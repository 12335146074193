import { useState } from "react";
import { Link as NavLink } from "react-router-dom";
import { Menu as MenuAntd, MenuProps, Space } from "antd";
import { isMobile } from "react-device-detect";
import {
  CheckCircleOutlined,
  ClearOutlined,
  FileOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  RetweetOutlined,
  SettingOutlined,
  ShoppingOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const MENT_ITEM_ENUM = {
  objects: "Объекты",
  templates: "Шаблоны",
  rents: "Брони",
  cleaners: "Горничные",
  cleans: "Уборки",
  settings: "Настройки",
  onboarding: "Обучение",
  tasks: "Задачи",
  updates: "Обновления",
};

const MENU_ITEMS = [
  {
    label: "Обновления",
    link: "/updates",
    key: "updates",
    icon: <RetweetOutlined />,
  },
  {
    label: "Обучение",
    link: "/onboarding",
    key: "onboarding",
    icon: <InfoCircleOutlined />,
  },
  {
    label: "Объекты",
    link: "/objects",
    key: "objects",
    icon: <HomeOutlined />,
  },
  {
    label: "Шаблоны",
    link: "/templates",
    key: "templates",
    icon: <FileOutlined />,
  },
  {
    label: "Брони",
    link: "/rents",
    key: "rents",
    icon: <TagOutlined />,
  },
  {
    label: "Горничные",
    link: "/cleaners",
    key: "cleaners",
    icon: <UserOutlined />,
  },
  {
    label: "Уборки",
    link: "/cleans",
    key: "cleans",
    icon: <ClearOutlined />,
  },
  {
    label: "Задачи",
    link: "/tasks",
    key: "tasks",
    icon: <CheckCircleOutlined />,
  },
  {
    label: "Собственники",
    link: "/owners",
    key: "owners",
    icon: <ShoppingOutlined />,
  },
  {
    label: "Настройки",
    link: "/settings",
    key: "settings",
    icon: <SettingOutlined />,
  },
];

export const Menu = ({ set }: { set: () => void }) => {
  const [current, setCurrent] = useState("links");

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    if (isMobile) {
      set();
    }
  };
  return (
    <>
      <div className="logo_block">
        {/* <img src={logo} className="logo"/> */}
        {localStorage.getItem("agency_id")}
      </div>
      <MenuAntd onClick={onClick} theme="dark" mode="inline" defaultSelectedKeys={["1"]} style={{ marginTop: 100 }}>
        {MENU_ITEMS.map((item) => (
          <MenuAntd.Item key={item.key}>
            <Space>
              {item.icon}
              <NavLink to={item.link}>{item.label}</NavLink>
            </Space>
          </MenuAntd.Item>
        ))}
      </MenuAntd>
    </>
  );
};
