import { Outlet, useLocation } from "react-router-dom";
import { Layout as AntLayout, Button, Space } from "antd";
import { isMobile } from "react-device-detect";
import { MENT_ITEM_ENUM, Menu } from "./Menu";
import { useQuery } from "react-query";
import { API } from "../config/api";
import { Suspense, useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useStore } from "../config/store";
const { Header, Sider, Content } = AntLayout;

// const DRAWERS: Record<DrawerName, any> = {
//   message: <MessageDrawer />,
//   rent: <RentDrawer />,
//   clean: <CleanDrawer />,
//   empty: null,
// };

export const Layout = () => {
  const [collapsed, setCollapsed] = useState(isMobile);
  const location = useLocation();
  // useQuery("cleaners", API.cleaners.getCleaners);
  useQuery("objects", API.objects.getObjects);
  useQuery("messages", API.messages.getMessages);

  const path = location.pathname.slice(1) as keyof typeof MENT_ITEM_ENUM;

  const { getInitialData } = useStore();

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      <AntLayout style={{ minHeight: "100vh" }}>
        <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth="0">
          <Menu set={() => setCollapsed(true)} />
        </Sider>
        <AntLayout>
          <Header style={{ padding: 0, background: "white" }}>
            <Space>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <span style={{ fontWeight: 700 }}>{MENT_ITEM_ENUM[path]?.toUpperCase() || ""}</span>
            </Space>
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            <Suspense fallback={<div>...</div>}>
              <Outlet />
            </Suspense>
          </Content>
        </AntLayout>
        {/* {drawer.open && <Drawer name={drawer.name}>{DRAWERS[drawer.name]}</Drawer>} */}
      </AntLayout>
    </>
  );
};
