import { create } from "zustand";
import { DrawerMode, DrawerName, ICleaner, IDrawer, IObject, IOta, IPaymentResult } from "./types";
import { API } from "./api";
import { App } from "antd";

interface IState {
  loaded: boolean;
  agency: any;
  objects: IObject[];
  cleaners: ICleaner[];
  drawer: IDrawer;
  sources: any;
  invocies: any[];
  otas: IOta[];
  cur: any;
  setDrawer: (drawer: Partial<IDrawer>, cur?: any) => void;
  closeDrawer: () => void;
  addHistory: (name: string, data?: any) => void;
  addPayment: (amount?: number, invoice_id?: number) => void;
  deletePayment: () => void;
  changeRent: (dto: any) => void;
  getRent: (id: any) => void;
  getClean: (id: any) => void;
  editCurClean: (dto: any) => void;
  getInitialData: () => void;
}

const EMPTY_DRAWER: IDrawer = {
  open: false,
  // cur: null,
  name: "empty",
};

export const useStore = create<IState>((set, get) => ({
  loaded: false,
  agency: null,
  objects: [],
  cleaners: [],
  otas: [],
  drawer: EMPTY_DRAWER,
  cur: null,
  sources: null,
  invocies: [],
  setDrawer: (newDrawer, cur) => {
    const { drawer } = get();
    let changes: any = {
      drawer: {
        ...drawer,
        ...newDrawer,
      },
    };
    if (cur) {
      changes = {
        ...changes,
        cur,
      };
    }
    set(changes);
  },
  closeDrawer: () => set({ drawer: EMPTY_DRAWER, cur: null }),
  changeRent: async (dto) => {
    await API.rents.changeRent(dto);
    const { drawer, cur } = get();
    if (dto.defaultDeposit !== undefined) {
      const depositInfo: IPaymentResult = {
        needToPay: dto.defaultDeposit - cur.depositInfo.paid,
        all: dto.defaultDeposit,
        paid: cur.depositInfo.paid,
      };
      set({
        drawer: {
          ...drawer,
          // cur: { ...drawer.cur, ...dto, depositInfo }
        },
        cur: { ...cur, ...dto, depositInfo },
      });
    } else {
      set({
        drawer: {
          ...drawer,
          // cur: { ...drawer.cur, ...dto }
        },
        cur: { ...cur, ...dto },
      });
    }
  },
  getRent: async (id) => {
    const rent = await API.rents.getRent(id);
    if (rent) {
      set({
        drawer: {
          open: true,
          name: "rent",
          // cur: rent
        },
        cur: rent,
      });
    }
    // const { drawer } = get();
    // if (dto.defaultDeposit !== undefined) {
    //   const depositInfo: IPaymentResult = {
    //     needToPay: dto.defaultDeposit - drawer.cur.depositInfo.paid,
    //     all: dto.defaultDeposit,
    //     paid: drawer.cur.depositInfo.paid,
    //   };
    //   set({ drawer: { ...drawer, cur: { ...drawer.cur, ...dto, depositInfo } } });
    // } else {
    //   set({ drawer: { ...drawer, cur: { ...drawer.cur, ...dto } } });
    // }
  },
  getClean: async (id) => {
    const clean = await API.cleans.getClean(id);
    if (clean) {
      set({ drawer: { open: true, name: "clean", mode: "view" }, cur: clean });
    }
  },
  newClean: async () => {
    set({ drawer: { open: true, name: "clean" }, cur: null });
  },
  addHistory: async (name: any, data: any) => {
    const { drawer, cur } = get();
    const rent_id = cur.id;
    await API.history.addHistory({ name, rent_id, data });
    const newHistories = [...cur.histories, { name, rent_id }];
    set({
      drawer: {
        ...drawer,
        // cur: { ...drawer.cur, histories: newHistories }
      },
      cur: { ...cur, histories: newHistories },
    });
  },
  deletePayment: async () => {
    const { drawer, cur } = get();
    const rent_id = cur.id;
    const name = "payment";
    await API.history.deleteHistory({ name, rent_id });
    const newHistories = cur.histories.filter((item: any) => item.name !== name);
    set({
      drawer: {
        ...drawer,
        // cur: { ...drawer.cur, histories: newHistories, checkManualPayment: { needToCheck: false } },
      },
      cur: { ...cur, histories: newHistories, checkManualPayment: { needToCheck: false } },
    });
  },
  addPayment: async (amount, invoice_id) => {
    const { drawer, cur } = get();
    const rent_id = cur.id;
    amount = amount || cur.paymentInfo.needToPay;
    let data: any = { object_id: cur.object_id, amount, rent_id };
    if (invoice_id) {
      data = { ...data, invoice_id };
    }
    const response = await API.rents.addPayment(data);
    if (response.success == true) {
      const newPaymentInfo = { ...cur.paymentInfo, needToPay: cur.paymentInfo.all - amount!, paid: amount };
      set({ cur: { ...cur, checkManualPayment: false, paymentInfo: newPaymentInfo } });
    } else {
      console.log("ERROR");
    }
  },
  editCurClean: async (dto) => {
    console.log("🚀 ~ editCurClean: ~ dto:", dto);
    const { cur } = get();
    set({ cur: { ...cur, ...dto } });
  },
  getInitialData: async () => {
    const cleaners = await API.cleaners.getCleaners();
    const objects = await API.objects.getObjects();
    const agency = await API.agency.getSettings();
    const sources = await API.constants.getSources();
    const invocies = await API.settings.getInvoices();
    const otas = await API.otas.getOtas();
    set({ cleaners, objects, agency, sources, invocies, otas, loaded: true });
  },
}));
