import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import { ConfigProvider, App as AntdApp } from "antd";
import "dayjs/locale/ru";
import locale from "antd/locale/ru_RU";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import "./index.css";
dayjs.extend(updateLocale);
dayjs.updateLocale("ru_RU", {
  weekStart: 4,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <AntdApp>
      <ConfigProvider locale={locale}>
        <App />
      </ConfigProvider>
    </AntdApp>
  </QueryClientProvider>
);
