import { lazy, Suspense } from "react";
import { App as AntdApp } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Layout } from "./components/Layout";
import "./App.css";

const Objects = lazy(() => import("./pages/Objects"));
const Object = lazy(() => import("./pages/Object"));
const Rents = lazy(() => import("./pages/Rents"));
const Cleaners = lazy(() => import("./pages/Cleaners"));
const Login = lazy(() => import("./pages/Login"));
const Settings = lazy(() => import("./pages/Settings"));
const Cleans = lazy(() => import("./pages/Cleans"));
const Templates = lazy(() => import("./pages/Templates"));
const Owners = lazy(() => import("./pages/Owners"));
const Owner = lazy(() => import("./pages/Owner"));
const Cleaner = lazy(() => import("./pages/Cleaner"));
const Onboarding = lazy(() => import("./pages/Onboarding"));
const Tasks = lazy(() => import("./pages/Tasks"));
const Updates = lazy(() => import("./pages/Updates"));

const routesConfig = [
  {
    path: "/login",
    element: (
      <Suspense fallback={<div>...</div>}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/owner/:id",
    element: <Owner />,
  },
  {
    path: "/cleaner/:id",
    element: <Cleaner />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Updates />,
      },
      {
        path: "/onboarding",
        element: <Onboarding />,
      },
      {
        path: "/objects",
        element: <Objects />,
      },
      {
        path: "/objects/:id",
        element: <Object />,
      },
      {
        path: "/rents",
        element: <Rents />,
      },
      {
        path: "/rents/:id",
        element: <Rents />,
      },
      {
        path: "/cleaners",
        element: <Cleaners />,
      },
      {
        path: "/cleaners/:id",
        element: <Cleaner />,
      },
      {
        path: "/cleans",
        element: <Cleans />,
      },
      {
        path: "/cleans/:id",
        element: <Cleans />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/templates",
        element: <Templates />,
      },
      {
        path: "/owners",
        element: <Owners />,
      },
      {
        path: "/tasks",
        element: <Tasks />,
      },
      {
        path: "/updates",
        element: <Updates />,
      },
    ],
  },
];

const router = createBrowserRouter(routesConfig);

function App() {
  return (
    <div className="App">
      <AntdApp>
        <RouterProvider router={router} />
      </AntdApp>
    </div>
  );
}

export default App;
