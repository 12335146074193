import Axios from "axios";
import { IAddon, IClean, IObject, IPoint, IRent, ITemplate, IUpdate } from "./types";
import dayjs from "dayjs";
import { notification } from "antd";

// export const URL = "http://82.146.33.180:5599";
export const FRONTEND_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://crm.realtycalendar.ru";
export const URL = process.env.NODE_ENV === "development" ? "http://localhost:5585" : "https://crm.realtycalendar.ru";
// export const URL = process.env.NODE_ENV === "development" ? "http://localhost:5585" : "http://82.146.33.180:5599";

export const axios = Axios.create({
  // baseURL: "http://82.146.33.180:5599/api/admin",
  // baseURL: "http://localhost:5585/api/admin",
  baseURL: `${URL}/api/admin`,
  // process.env.NODE_ENV === "development" ? "http://localhost:5585/api/admin" : "http://82.146.33.180:5599/api/admin",
});

// export interface IItem {
//   id: string;
//   name: string;
//   description: string;
//   content: string;
//   user_count: string;
// }

// export interface IHistory {
//   id: number;
//   user_id: string;
//   event: string;
//   data: string;
// }

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // config.headers.Authorization = `Bearer Jr6%9TEc5Km6muzJ7Gk@Lznx7`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/login";
    } else {
      notification.error({
        message: "Ошибка сети",
        description: "Не удалось отправить запрос на сервер",
      });
    }
  }
);

export const API = {
  async login(dto: any) {
    try {
      const { data } = await axios.post<any>("agency/login", dto);
      return data;
    } catch (error) {
      console.log(" error:", error);
    }
  },
  files: {
    async getFiles() {
      try {
        const { data } = await axios.get<any>("files");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addFile(dto: any) {
      try {
        const { data } = await axios.post<any>("files", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async deleteFile(id: any) {
      try {
        const { data } = await axios.delete<any>("files/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  settings: {
    async getSettings() {
      try {
        const { data } = await axios.get<any>("agency");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getAutomessages() {
      try {
        const { data } = await axios.get<any>("agency/automessages");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addAutomessages(dto: any) {
      try {
        const { data } = await axios.post<any>("agency/automessages", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async checkAutomessages(dto: any) {
      try {
        const { data } = await axios.post<any>("agency/automessages/check", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getInvoices() {
      try {
        const { data } = await axios.get<any>("agency/invoices");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeContacts(dto: any) {
      try {
        const { data } = await axios.patch<any>("agency/contacts", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeSettings(dto: any) {
      try {
        const { data } = await axios.patch<any>("agency/settings", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async checkHooks() {
      try {
        const { data } = await axios.get<any>("agency/hooks");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addHooks() {
      try {
        const { data } = await axios.post<any>("agency/hooks");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async createTestTopic() {
      try {
        const { data } = await axios.post<any>("agency/topic");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getCleanCommonInstruction() {
      try {
        const { data } = await axios.get<any>("agency/cleaninstruction");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addCleanCommonInstruction(dto: any) {
      try {
        const { data } = await axios.post<any>("agency/cleaninstruction", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeCleanCommonInstruction(dto: any) {
      try {
        const { data } = await axios.patch<any>("agency/cleaninstruction", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  constants: {
    async getSources() {
      try {
        const { data } = await axios.get<any>("agency/sources");
        return data.data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  agency: {
    async getSettings() {
      try {
        const { data } = await axios.get<any>("agency");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getOnboarding() {
      try {
        const { data } = await axios.get("agency/onboarding");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  checks: {
    async checkBots() {
      try {
        const { data } = await axios.get<any>("agency/bots");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  messages: {
    async getMessages() {
      try {
        const { data } = await axios.get<any>("messages");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addMessage(dto: { name: string; text: string }) {
      try {
        const { data } = await axios.post("messages", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeMessage(dto: { id: any; name: string; text: string }) {
      try {
        const { data } = await axios.patch("messages", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  objects: {
    async getObjects() {
      try {
        const { data } = await axios.get<any[]>("objects");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getObjectsForSelect() {
      try {
        const { data } = await axios.get<any[]>("objects/select");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async loadObjects(timezone: any) {
      try {
        const { data } = await axios.post<any>("objects/load", { timezone });
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getObject(id: any) {
      try {
        const { data } = await axios.get<IObject>("objects/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeObject(dto: any) {
      try {
        const { data } = await axios.patch("objects", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async activeAllObjects(dto: any) {
      try {
        const { data } = await axios.patch("objects/activeall", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  points: {
    async getPoints(id: any) {
      try {
        const { data } = await axios.get<IPoint[]>("points/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addPoint(dto: { name: string; desc: string; object_id: any }) {
      try {
        const { data } = await axios.post("points", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changePoint(dto: { name: string; desc: string; id: any }) {
      try {
        const { data } = await axios.patch("points", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async upPoint(dto: { index: number; id: any; object_id: any }) {
      try {
        const { data } = await axios.patch("points/up", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async deletePoint(id: number) {
      try {
        const { data } = await axios.delete("points/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  instructions: {
    async getInstruction(id: any) {
      try {
        const { data } = await axios.get("objects/instruction/" + id);
        return data;
      } catch (error: any) {
        throw new Error("Ошибка при получении данных: " + error.message);
      }
    },
    async addInstruction(dto: any) {
      try {
        const { data } = await axios.post<IObject>("objects/instruction/", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async editInstruction(dto: any) {
      try {
        const { data } = await axios.patch<IObject>("objects/instruction/", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  templates: {
    async getTemplates() {
      try {
        const { data } = await axios.get<ITemplate[]>("templates");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getTemplatesOptions() {
      try {
        const { data } = await axios.get<any[]>("templates/options");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addTemplate(dto: { name: string; text: string }) {
      try {
        const { data } = await axios.post("templates", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeTemplate(dto: { id: number; name: string; text: string }) {
      try {
        const { data } = await axios.patch("templates", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async deleteTemplate(id: number) {
      try {
        const { data } = await axios.delete("templates/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addTemplateToPoints(id: number) {
      try {
        const { data } = await axios.patch("templates/bulk", { id });
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  addons: {
    async getAddons(id: any) {
      try {
        const { data } = await axios.get<IAddon[]>("addons/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addAddon(dto: { name: string; desc: string; object_id: any }) {
      try {
        const { data } = await axios.post("addons", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeAddon(dto: { name: string; desc: string; id: any }) {
      try {
        const { data } = await axios.patch("addons", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async deleteAddon(id: any) {
      try {
        const { data } = await axios.delete("addons/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },

  async getRents(object_id: any | null) {
    try {
      const { data } = await axios.get<IRent[]>(object_id ? `rents?object_id=${object_id}` : "rents");
      return data;
    } catch (error) {
      console.log(" error:", error);
    }
  },
  // async getCleans(period: string) {
  //   try {
  //     const { data } = await axios.get<any[]>(`cleans?period=${period}`);
  //     return data;
  //   } catch (error) {
  //     console.log(" error:", error);
  //   }
  // },
  updates: {
    async getUpdates() {
      try {
        const { data } = await axios.get<IUpdate[]>("updates");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  cleans: {
    async getCleans(dto: any) {
      const { begin, end, cleaner_id, object_id } = dto;
      try {
        let url = `cleans?begin=${dayjs(begin).format("YYYY-MM-DD")}&end=${dayjs(end).format("YYYY-MM-DD")}`;
        if (cleaner_id) {
          url = url + `&cleaner_id=${cleaner_id}`;
        }
        if (object_id) {
          url = url + `&object_id=${object_id}`;
        }
        const { data } = await axios.get<{ cleans: IClean[]; amount: number; count: number }>(url);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addClean(dto: any) {
      try {
        const { data } = await axios.post("cleans", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeClean(dto: any) {
      try {
        const { data } = await axios.patch("cleans", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    // async addCleanerToRent(dto: any) {
    //   try {
    //     const { data } = await axios.patch("cleans/add", dto);
    //     return data;
    //   } catch (error) {
    //     console.log(" error:", error);
    //   }
    // },
    async getClean(id: any) {
      try {
        const { data } = await axios.get("cleans/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  history: {
    async deleteHistory(dto: { name: string; rent_id: number }) {
      try {
        const { data } = await axios.patch("history/delete_payment", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addHistory(dto: { name: string; rent_id: number; data?: any }) {
      try {
        const { data } = await axios.post("history", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },

  async getSteps() {
    try {
      const { data } = await axios.get<any[]>("steps");
      return data;
    } catch (error) {
      console.log(" error:", error);
    }
  },
  async changeStep(dto: { id: number; heading?: string; text?: string; index?: number }) {
    try {
      const { data } = await axios.patch<any[]>("steps", dto);
      return data;
    } catch (error) {
      console.log(" error:", error);
    }
  },
  async deleteStep(id: number) {
    try {
      const { data } = await axios.delete("steps/" + id);
      return data;
    } catch (error) {
      console.log(" error:", error);
    }
  },
  otas: {
    async getOtas() {
      try {
        const { data } = await axios.get<any[]>("agency/otas");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addOta(dto: any) {
      try {
        const { data } = await axios.post("agency/otas", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async editOta(dto: any) {
      try {
        const { data } = await axios.patch("agency/otas", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  cleaners: {
    async getCleaners() {
      try {
        const { data } = await axios.get<any[]>("cleaners");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async deleteCleaner(id: any) {
      try {
        const { data } = await axios.delete("cleaners/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async activateCleaner(id: any) {
      try {
        const { data } = await axios.patch("cleaners", { id, active: true });
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getCleansByCleanerId(dto: any) {
      try {
        const { data } = await axios.get<{ cleans: IClean[]; amount: number; count: number; name: string }>(
          `cleaners/${dto.id}?begin=${dayjs(dto.begin).format("YYYY-MM-DD")}&end=${dayjs(dto.end).format("YYYY-MM-DD")}`
        );
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addCleaner(dto: any) {
      try {
        const { data } = await axios.post("cleaners", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addObjectToCleaner(dto: any) {
      try {
        const { data } = await axios.post("cleaners/object", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async deleteObjectFromCleaner(dto: any) {
      try {
        const { data } = await axios.patch("cleaners/object/delete", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async editCostOfCleanerObject(dto: any) {
      try {
        const { data } = await axios.patch("cleaners/object", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeCleaner(dto: any) {
      try {
        const { data } = await axios.patch("cleaners", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  rents: {
    async changeRent(dto: any) {
      try {
        const { data } = await axios.patch("rents", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getRent(id: any) {
      try {
        const { data } = await axios.get("rents/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async refreshRents() {
      try {
        const { data } = await axios.post("rents/refresh");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addPayment(dto: { amount: any; object_id: any; rent_id: any }) {
      try {
        const { data } = await axios.post("rents/payment", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  tasks: {
    async getTasks() {
      try {
        const { data } = await axios.get<any[]>("tasks");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeTask(dto: any) {
      try {
        const { data } = await axios.patch<any[]>("tasks", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  owners: {
    async getOwners() {
      try {
        const { data } = await axios.get<any[]>("owners");
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async addOwner(dto: any) {
      try {
        const { data } = await axios.post("owners", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async changeOwner(dto: any) {
      try {
        const { data } = await axios.patch("owners", dto);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async deleteOwner(id: any) {
      try {
        const { data } = await axios.delete("owners/" + id);
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
    async getRentsByOwnerId(dto: any) {
      try {
        const { data } = await axios.get<any>(
          `owners/rents/${dto.id}?begin=${dayjs(dto.begin).format("YYYY-MM-DD")}&end=${dayjs(dto.end).format(
            "YYYY-MM-DD"
          )}`
        );
        return data;
      } catch (error) {
        console.log(" error:", error);
      }
    },
  },
  async loadRentsFromRC() {
    try {
      const { data } = await axios.post("rents/fetch");
      return data;
    } catch (error) {
      console.log(" error:", error);
    }
  },
};
